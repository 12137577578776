import React from "react"
import { Link } from "gatsby"
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import logo from "images/logo.svg"

//const pkg = require("root/package.json")
const pjt = require("root/project.json")

const Header = props => (
  <header className="section is-header">
    <div className="inner">
      <div className="grid is-gap-none is-between is-middle">
        <div className="col">
          <Link className="logo" to="/">
            <img src={logo} alt={pjt.site.title} />
          </Link>
        </div>
      </div>
    </div>
  </header>
)

export default Header
