import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Media from "react-media"
import StickyBox from "react-sticky-box"

import Header from "components/Header"
import Aside from "components/Aside"
import Footer from "components/Footer"
import FixedMenu from "components/FixedMenu"
import "components/layout.scss"

import "lazysizes"
import "lazysizes/plugins/attrchange/ls.attrchange"

import "@fortawesome/fontawesome-svg-core/styles.css"
import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faBars,
  faExternalLinkAlt,
  faShoppingCart,
  faShoppingBasket,
  faShareAlt,
  faCameraRetro,
  faFeather,
  faHome,
  faStore,
  faUtensils,
  faBroom,
  faBath,
  faCarrot,
  faBook,
  faMitten,
  faPaw,
  faHeartbeat
} from "@fortawesome/free-solid-svg-icons"
import { faCalendarAlt, faLightbulb } from "@fortawesome/free-regular-svg-icons"
import {
  faTwitter,
  faInstagram,
  faFacebookSquare,
  faLine,
  faGetPocket
} from "@fortawesome/free-brands-svg-icons"

library.add(
  faBars,
  faExternalLinkAlt,
  faShoppingCart,
  faShoppingBasket,
  faShareAlt,
  faCameraRetro,
  faCalendarAlt,
  faLightbulb,
  faFeather,
  faHome,
  faStore,
  faUtensils,
  faBroom,
  faBath,
  faCarrot,
  faBook,
  faMitten,
  faPaw,
  faHeartbeat,
  faTwitter,
  faInstagram,
  faFacebookSquare,
  faLine,
  faGetPocket
)

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `}
    render={data => (
      <>
        <FixedMenu />
        <Header />
        <main className="section is-main">
          <div className="inner">
            <div className="grid-system is-main">
              <div className="col">{children}</div>
              <div className="col">
                <Media query="(min-width: 992px)">
                  {matches =>
                    matches ? (
                      <StickyBox offsetTop={8} offsetBottom={8}>
                        <Aside />
                      </StickyBox>
                    ) : (
                      <Aside />
                    )
                  }
                </Media>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
