import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { DateTime } from "luxon"

class CardTip extends React.Component {
  render() {
    return (
      <Link className="card is-border is-angle-right" to={this.props.to}>
        <div className="inner is-padding-lg">
          <div className="grid is-middle">
            {this.props.src ? (
              <div className="col">
                <div className="card-thumb-wrap is-width-48px">
                  <img
                    data-src={this.props.src}
                    className="img is-radius is-centering is-fade lazyload"
                    alt={this.props.title}
                  />
                </div>
              </div>
            ) : null}
            <div className="col is-mobile-0 is-space-quarter">
              <p className="text is-dark-2">{this.props.title}</p>
              <p className="text is-font-en-modern is-dark-4 is-space-right-half is-xs">
                <span className="text is-inline-block">
                  <FontAwesomeIcon
                    className="icon"
                    icon={["far", "calendar-alt"]}
                  />
                  <span className="text">公開日: </span>
                  <span className="text">
                    {DateTime.fromISO(this.props.publishedAt, {
                      zone: "Asia/Tokyo"
                    }).toFormat("yyyy/MM/dd")}
                  </span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </Link>
    )
  }
}

export default CardTip
