import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//const pkg = require("root/package.json")
//const pjt = require("root/project.json")

function ListCategory() {
  return (
    <StaticQuery
      query={categoryQuery}
      render={data => {
        return (
          <ul className="list is-note is-item-margin-top-xl is-item-margin-left-lg">
            <li className="item">
              <span className="icon is-fit-lg is-dark-5">
                <FontAwesomeIcon icon={["fas", "store"]} />
              </span>
              <span className="text">
                <Link
                  className="text is-link-reverse is-primary"
                  to={`/category/`}
                >
                  <span className="text">すべて</span>
                </Link>
                <span className="text is-palt is-dark-5 is-xs">
                  &nbsp;（
                  {(() => {
                    const allItems = data.items.edges
                    const result = allItems.filter(function(value) {
                      return value.node.data.published === true
                    })
                    return result.length
                  })()}
                  ）
                </span>
              </span>
            </li>
            {data.categories.edges.map((edge, i) =>
              edge.node.data.published === true ? (
                <li className="item" key={i}>
                  <span className="icon is-fit-lg is-dark-5">
                    <FontAwesomeIcon
                      icon={[
                        edge.node.data.icon_style,
                        edge.node.data.icon_name
                      ]}
                    />
                  </span>
                  <span>
                    <Link
                      className="text is-link-reverse is-primary"
                      to={`/category/${edge.node.data.slug}/`}
                    >
                      <span className="text">{edge.node.data.name}</span>
                    </Link>
                    <span className="text is-palt is-dark-5 is-xs">
                      &nbsp;（
                      {(() => {
                        const items = edge.node.data.items
                        const result = items.filter(function(value) {
                          return value.data.published === true
                        })
                        return result.length
                      })()}
                      ）
                    </span>
                  </span>
                </li>
              ) : null
            )}
          </ul>
        )
      }}
    />
  )
}

const categoryQuery = graphql`
  query categoryQuery {
    items: allAirtable(filter: { table: { eq: "items" } }) {
      edges {
        node {
          data {
            published
            slug
          }
        }
      }
    }
    categories: allAirtable(filter: { table: { eq: "categories" } }) {
      edges {
        node {
          data {
            name
            published
            slug
            icon_style
            icon_name
            items {
              data {
                published
              }
            }
          }
        }
      }
    }
  }
`

export default ListCategory
