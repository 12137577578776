import React from "react"
import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery-no-icon.css"

class Gallery extends React.Component {
  //constructor(props) {
  //  super(props)
  //  this.state = {
  //    mask: true
  //  }
  //}
  //handleImageLoad = () => {
  //  this.setState({
  //    mask: false
  //  })
  //}
  renderItem = item => {
    return (
      <div className="image-gallery-image">
        <img
          data-src={item.original}
          alt={item.originalAlt}
          srcSet={item.srcSet}
          sizes={item.sizes}
          title={item.originalTitle}
          onLoad={this.props.onImageLoad}
          className="img is-fade lazyload"
        />
      </div>
    )
  }
  renderThumbInner = item => {
    return (
      <div className="image-gallery-thumbnail-inner">
        <img
          data-src={item.thumbnail}
          alt={item.thumbnailAlt}
          title={item.originalTitle}
          className="img is-fade lazyload"
        />
      </div>
    )
  }
  render() {
    const images = this.props.images.map(image => ({
      original: image.thumbnails.full.url,
      thumbnail: image.thumbnails.full.url
    }))
    return (
      <div className="image-gallery-area">
        <div className="image-gallery-centering">
          <div className="image-gallery-relative is-1x1-with-thumb">
            {
              //<div
              //className={(() => {
              //  if (this.state.mask === true) {
              //    return "image-gallery-mask is-active"
              //  } else {
              //    return "image-gallery-mask"
              //  }
              //})()}
              ///>
            }
            <div className="image-gallery-absolute">
              <ImageGallery
                items={images}
                //lazyLoad={true}
                showPlayButton={false}
                showIndex={true}
                showFullscreenButton={false}
                useBrowserFullscreen={false}
                onThumbnailClick={event => {
                  event.stopPropagation()
                }}
                //onImageLoad={this.handleImageLoad}
                renderItem={this.renderItem}
                renderThumbInner={this.renderThumbInner}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Gallery
