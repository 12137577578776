import React from "react"
import { Link, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//import { DateTime } from "luxon"
//import rehypeReact from "rehype-react"

import retext from "retext"
import pos from "retext-pos"
import keywords from "retext-keywords"

import Layout from "components/Layout"
import Head from "components/Head"
import Gallery from "components/Gallery"
import ShareBtns from "components/ShareBtns"
import CardTip from "components/CardTip"
import CardRelation from "components/CardRelation"

//const pkg = require("root/package.json")
const pjt = require("root/project.json")

//const renderAst = new rehypeReact({
//  createElement: React.createElement,
//  components: {
//    img: ZoomImage
//  }
//}).Compiler

export default ({ data }) => (
  <Layout>
    <Head
      id="item"
      pageTitle={data.airtable.data.title}
      pageDescription={data.airtable.data.content.childMarkdownRemark.excerpt}
      pageUrl={pjt.site.url + "/items/" + data.airtable.data.slug + "/"}
      pageOgImage={
        data.airtable.data.images ? data.airtable.data.images[0].url : null
      }
    />
    <section className="section is-item is-space-half">
      <article className="card is-separate">
        <header className="card-header">
          <div className="inner">
            <h1 className="text is-dark-2 is-lg">{data.airtable.data.title}</h1>
          </div>
        </header>
        <section className="card-content">
          <div className="inner is-padding-lg is-tablet-padding-xxl is-space-2x">
            {data.airtable.data.images ? (
              <Gallery images={data.airtable.data.images} />
            ) : null}
            <div className="grid-system is-item-data">
              <div className="col">
                <div
                  className="wysiwyg is-external-link-area is-space is-sm"
                  dangerouslySetInnerHTML={{
                    __html: data.airtable.data.content.childMarkdownRemark.html
                  }}
                />
              </div>
              <div className="col">
                <div className="card-content-child is-wide-sticky is-space">
                  <h2 className="heading is-strong">
                    <span className="icon">
                      <FontAwesomeIcon icon={["fas", "shopping-cart"]} />
                    </span>
                    <span className="text">購入ガイド</span>
                  </h2>
                  {data.airtable.data.guide ? (
                    <p className="text is-sm">{data.airtable.data.guide}</p>
                  ) : null}
                  <div className="btns">
                    {(() => {
                      if (data.airtable.data.buy_links) {
                        const buyLinks = data.airtable.data.buy_links.split(
                          "\n"
                        )
                        const filterBuyLinks = buyLinks.filter(s => s != "")
                        return filterBuyLinks.map((filterBuyLink, i) => {
                          const checkAmazon = /^https:\/\/amzn\.to|^https:\/\/www\.amazon/
                          const checkRakuten = /^https:\/\/a\.r10\.to|^https:\/\/r10\.to|^https:\/\/item\.rakuten/
                          const checkUrl = /^http/
                          if (filterBuyLink.match(checkAmazon)) {
                            return (
                              <a
                                href={filterBuyLink}
                                className="btn is-plain is-amazon is-mobile-full"
                                target="_blank"
                                rel="noopener"
                                key={i}
                              >
                                <span className="text">Amazon 商品ページ</span>
                                <span className="icon is-xs">
                                  <FontAwesomeIcon
                                    icon={["fas", "external-link-alt"]}
                                  />
                                </span>
                              </a>
                            )
                          } else if (filterBuyLink.match(checkRakuten)) {
                            return (
                              <a
                                href={filterBuyLink}
                                className="btn is-plain is-rakuten is-mobile-full"
                                target="_blank"
                                rel="noopener"
                                key={i}
                              >
                                <span className="text">楽天 商品ページ</span>
                                <span className="icon is-xs">
                                  <FontAwesomeIcon
                                    icon={["fas", "external-link-alt"]}
                                  />
                                </span>
                              </a>
                            )
                          } else if (filterBuyLink.match(checkUrl)) {
                            return (
                              <a
                                href={filterBuyLink}
                                className="btn is-plain is-primary is-mobile-full"
                                target="_blank"
                                rel="noopener noreferrer"
                                key={i}
                              >
                                <span className="text">Webサイト</span>
                                <span className="icon is-xs">
                                  <FontAwesomeIcon
                                    icon={["fas", "external-link-alt"]}
                                  />
                                </span>
                              </a>
                            )
                          }
                        })
                      }
                    })()}
                    {/*data.airtable.data.amazon ? (
                      <a
                        href={data.airtable.data.amazon}
                        className="btn is-plain is-amazon is-mobile-full"
                        target="_blank"
                        rel="noopener"
                      >
                        <span className="text">Amazon 商品ページ</span>
                        <span className="icon is-xs">
                          <FontAwesomeIcon
                            icon={["fas", "external-link-alt"]}
                          />
                        </span>
                      </a>
                    ) : null*/}
                    {/*data.airtable.data.rakuten ? (
                      <a
                        href={data.airtable.data.rakuten}
                        className="btn is-plain is-rakuten is-mobile-full"
                        target="_blank"
                        rel="noopener"
                      >
                        <span className="text">楽天 商品ページ</span>
                        <span className="icon is-xs">
                          <FontAwesomeIcon
                            icon={["fas", "external-link-alt"]}
                          />
                        </span>
                      </a>
                    ) : null*/}
                    {/*data.airtable.data.link ? (
                      <a
                        href={data.airtable.data.link}
                        className="btn is-plain is-primary is-mobile-full"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="text">Webサイト</span>
                        <span className="icon is-xs">
                          <FontAwesomeIcon
                            icon={["fas", "external-link-alt"]}
                          />
                        </span>
                      </a>
                    ) : null*/}
                  </div>
                  {data.airtable.data.note_link ? (
                    <>
                      <h2 className="heading is-strong">
                        <span className="icon">
                          <FontAwesomeIcon icon={["fas", "book"]} />
                        </span>
                        <span className="text">note作品</span>
                      </h2>
                      <div className="btns">
                        <a
                          href={data.airtable.data.note_link}
                          className="btn is-plain is-note is-mobile-full"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="text">商品が登場するマンガ</span>
                          <span className="icon is-xs">
                            <FontAwesomeIcon
                              icon={["fas", "external-link-alt"]}
                            />
                          </span>
                        </a>
                      </div>
                    </>
                  ) : null}
                  <h2 className="heading is-strong">
                    <span className="icon">
                      <FontAwesomeIcon icon={["fas", "share-alt"]} />
                    </span>
                    <span className="text">シェア</span>
                  </h2>
                  <ShareBtns
                    pageTitle={data.airtable.data.title}
                    pageUrl={
                      pjt.site.url + "/items/" + data.airtable.data.slug + "/"
                    }
                  />
                </div>
              </div>
            </div>
            {data.airtable.data.tips ? (
              <div className="box is-space">
                <h2 className="heading is-strong">
                  <span className="icon is-lg">
                    <FontAwesomeIcon icon={["far", "lightbulb"]} />
                  </span>
                  <span className="text">便利な使い方</span>
                </h2>
                <ul className="grid is-gap-2x">
                  {data.airtable.data.tips.map((edge, i) =>
                    edge.data.published === true ? (
                      <li className="col is-mobile-12" key={i}>
                        <CardTip
                          to={`/tips/${edge.data.slug}/`}
                          src={
                            edge.data.images
                              ? edge.data.images[0].thumbnails.small.url
                              : null
                          }
                          title={edge.data.title}
                          publishedAt={edge.data.publishedAt}
                        />
                      </li>
                    ) : null
                  )}
                </ul>
              </div>
            ) : null}
            {data.airtable.data.relations ? (
              <div className="box is-space">
                <h2 className="heading is-strong">
                  <span className="icon is-lg">
                    <FontAwesomeIcon icon={["fas", "shopping-basket"]} />
                  </span>
                  <span className="text">関連商品</span>
                </h2>
                <ul className="grid is-gap-2x">
                  {data.airtable.data.relations.map((edge, i) =>
                    edge.data.published === true ? (
                      <li className="col is-mobile-12 is-tablet-6" key={i}>
                        <CardRelation
                          to={`/items/${edge.data.slug}/`}
                          src={edge.data.images[0].thumbnails.large.url}
                          title={edge.data.title}
                        />
                      </li>
                    ) : null
                  )}
                </ul>
              </div>
            ) : null}
          </div>
        </section>
        <footer className="card-footer">
          <div className="inner">
            <div className="btns is-center is-sm">
              {data.airtable.data.categories.map(({ data }, index) =>
                data.published === true ? (
                  <Link
                    to={"/category/" + data.slug + "/"}
                    className="btn is-outline"
                    key={index}
                  >
                    <span className="icon is-dark-4">
                      <FontAwesomeIcon
                        icon={[data.icon_style, data.icon_name]}
                      />
                    </span>
                    <span className="text">{data.name}</span>
                  </Link>
                ) : null
              )}
            </div>
          </div>
        </footer>
      </article>
    </section>
  </Layout>
)

export const query = graphql`
  query GetItem($slug: String!) {
    airtable(table: { eq: "items" }, data: { slug: { eq: $slug } }) {
      data {
        title
        slug
        categories {
          data {
            name
            published
            slug
            icon_style
            icon_name
          }
        }
        images {
          url
          thumbnails {
            large {
              url
            }
            full {
              url
            }
          }
        }
        content {
          childMarkdownRemark {
            html
            excerpt(truncate: true)
          }
        }
        guide
        buy_links
        note_link
        relations {
          data {
            title
            published
            slug
            images {
              url
              thumbnails {
                large {
                  url
                }
              }
            }
          }
        }
        tips {
          data {
            title
            published
            slug
            publishedAt
            images {
              url
              thumbnails {
                small {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
