import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import ListCategory from "components/ListCategory"

import Mikan from "mikanjs"

import profile from "images/profile.png"

//const pkg = require("root/package.json")
const pjt = require("root/project.json")

function Aside() {
  return (
    <StaticQuery
      query={asideQuery}
      render={data => {
        return (
          <aside className="section is-aside is-space-half">
            <div className="card is-separate">
              <div className="card-header">
                <div className="inner is-padding-xs">
                  <h2 className="text is-strong is-font-en-modern is-uppercase is-sm">
                    Category
                  </h2>
                </div>
              </div>
              <div className="card-content">
                <div className="inner is-space">
                  <ListCategory />
                </div>
              </div>
            </div>

            <div className="card is-separate">
              <div className="card-header">
                <div className="inner is-padding-xs">
                  <h2 className="text is-strong is-font-en-modern is-uppercase is-sm">
                    For You
                  </h2>
                </div>
              </div>
              <div className="card-content">
                <div className="inner is-space">
                  <h3 className="heading is-border-tail-bottom is-strong is-center is-xs">
                    <span
                      className="text is-strong"
                      dangerouslySetInnerHTML={{
                        __html: Mikan(pjt.about.aside.heading)
                      }}
                    />
                  </h3>
                  <ul className="list is-circle is-palt is-xs">
                    {pjt.about.aside.list.map((list, i) => (
                      <li className="item" key={i}>
                        <span className="text">{list}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="card is-separate">
              <div className="card-header">
                <div className="inner is-padding-xs">
                  <h2 className="text is-strong is-font-en-modern is-uppercase is-sm">
                    Profile
                  </h2>
                </div>
              </div>
              <div className="card-content">
                <div className="inner is-space">
                  <div className="author-image">
                    <img
                      className="img is-centering"
                      src={profile}
                      alt={pjt.author.name}
                      width="200"
                    />
                  </div>
                  <p className="texts is-xs">
                    <span className="text is-strong">{pjt.author.name}</span>
                    <span className="text">：{pjt.author.description}</span>
                  </p>
                  <div className="btns is-xs">
                    {pjt.accounts.map(account => (
                      <a
                        href={account.url}
                        className="btn is-outline is-mobile-0"
                        key={account.name}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="icon">
                          <FontAwesomeIcon
                            icon={[account.icon.style, account.icon.name]}
                          />
                        </span>
                        <span className="text">{account.name}</span>
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </aside>
        )
      }}
    />
  )
}

const asideQuery = graphql`
  query AsideQuery {
    items: allAirtable(filter: { table: { eq: "items" } }) {
      edges {
        node {
          data {
            published
            slug
          }
        }
      }
    }
    categories: allAirtable(filter: { table: { eq: "categories" } }) {
      edges {
        node {
          data {
            name
            published
            slug
            icon_style
            icon_name
            items {
              data {
                published
              }
            }
          }
        }
      }
    }
  }
`

export default Aside
