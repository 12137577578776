import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import ListCategory from "components/ListCategory"

class FixedMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: false
    }
  }
  openMenu = () => {
    this.setState({
      menu: true
    })
  }
  closeMenu = () => {
    this.setState({
      menu: false
    })
  }
  render() {
    return (
      <div
        className={(() => {
          if (this.state.menu === true) {
            return "fixed-menu is-active"
          } else {
            return "fixed-menu"
          }
        })()}
      >
        <div className="fixed-menu-btns">
          <button className="btn is-outline is-circle" onClick={this.openMenu}>
            <span className="icon is-dark-3 is-lg">
              <FontAwesomeIcon icon={["fas", "bars"]} />
            </span>
          </button>
        </div>
        <div className="fixed-menu-wrap">
          <div className="fixed-menu-overlay" />
          <div className="fixed-menu-close-area" onClick={this.closeMenu} />
          <div className="fixed-menu-contents-area is-separate">
            <div className="content">
              <div className="inner is-padding-xs">
                <h2 className="text is-strong is-font-en-modern is-uppercase is-sm">
                  Menu
                </h2>
              </div>
            </div>
            <div className="content">
              <div className="inner is-padding">
                <ul className="list is-note is-item-margin-top-xl is-item-margin-left-lg">
                  <li className="item">
                    <span className="icon is-fit-lg is-dark-5">
                      <FontAwesomeIcon icon={["fas", "home"]} />
                    </span>
                    <span className="text">
                      <Link
                        className="text is-link-reverse is-primary"
                        to={`/`}
                      >
                        <span className="text">Home</span>
                      </Link>
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="content">
              <div className="inner is-padding-xs">
                <h2 className="text is-strong is-font-en-modern is-uppercase is-sm">
                  Category
                </h2>
              </div>
            </div>
            <div className="content">
              <div className="inner is-padding">
                <ListCategory />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FixedMenu
